// Swiper Class
import Swiper from './core/core.js';

//IMPORT_MODULES

const modules = [
  //INSTALL_MODULES
];

Swiper.use(modules);

//EXPORT
